<template>
  <div class="page">
    <section class="section-1">
      <img src="../../assets/images/call/banner.png" alt="" />
      <div class="content">联系我们</div>
    </section>
    <section class="section-2">
      <div class="content">
        <div class="map-img">
          <img src="../../assets/images/call/map.png" alt="" />
        </div>
        <div class="dialog">
          <img src="../../assets/images/call/dialog.png" alt="" />
          <div class="info">
            <div class="name">浙江刘小白智能科技有限公司</div>
            <div class="address-phone-info">
              <p class="address">地址：滨江区江虹路333号研祥科技大厦A座902室</p>
              <p class="phone">电话：4006-21-4006</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "index",
};
</script>

<style lang="less" scoped>
.page {
  .section-1 {
    margin-top: -63px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      z-index: -1;
      width: 100vw;
      height: 27.03vw;
    }

    .content {
      position: absolute;
      z-index: 99;
      height: 67px;
      font-size: 48px;
      font-weight: bold;
      line-height: 67px;
    }
  }

  .section-2 {
    width: 100vw;
    height: 568px;
    background: #ffffff;
    display: flex;
    align-items: center;

    .content {
      position: relative;
      margin: auto;
      width: 1200px;
      height: 408px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .map-img {
        width: 1200px;
        height: 408px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .dialog {
        position: absolute;
        top: 119px;
        left: 419px;

        img {
          width: 100%;
          height: 100%;
        }

        .info {
          position: absolute;
          top: 27px;
          left: 46px;
          width: 100%;
          height: 100%;

          .name {
            height: 28px;
            font-size: 20px;
            font-weight: bold;
            color: #333333;
            line-height: 28px;
          }

          .address-phone-info {
            height: 20px;
            font-size: 14px;
            color: #555555;
            line-height: 20px;

            p {
              margin-top: 8px;
            }
          }
        }
      }
    }
  }
}
</style>
